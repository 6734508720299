.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 15px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  max-width: 40vw;
  min-width: 400px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal > button {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.modal > button:hover {
  cursor: pointer;
  background-color: lightgrey;
}
