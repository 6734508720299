.workspace-wrapper {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.workspace-switcher {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  border-bottom: 1px solid lightgrey;
}

.workspace-switcher > button {
  width: 3rem;
  height: 1.5rem;
  border: 1px solid lightgrey;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: background-color 0.1s ease;
  background-color: white;
  position: relative;
  bottom: -1px;
}

.workspace-switcher > button:hover {
  background-color: lightgrey;
}

.workspace-switcher > button.enabled {
  border-bottom: transparent;
}

.workspace-switcher > button:hover {
  cursor: pointer;
}

.workspace-scheduler {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.workspace-scheduler-content {
  width: 74px;
  text-align: center;
}

.workspace-scheduler > .small-button {
  width: 2em;
  height: 2em;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.workspace-scheduler > .small-button:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.workspace-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
  margin-bottom: 0.8em;
}

li {
  list-style-type: none;
}

.workspace-entries {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  overflow-y: unset;
  max-height: auto;
}

@media (min-width: 768px) {
  .workspace-wrapper {
    width: 40vw;
  }
}

.workspace-entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin-bottom: 2em;
  @apply p-2;
}

.workspace-entry-enabled {
  transition: background-color 0.2s ease;
  background-color: rgb(255, 255, 255);
}

.workspace-entry-locked {
  transition: background-color 0.2s ease;
  background-color: rgb(241, 241, 241);
  /* background-color: rgb(238, 238, 110); */
}

.workspace-entry-disabled {
  transition: background-color 0.2s ease;
  background-color: rgb(200, 200, 200);
}

.workspace-entry-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
  /* top: -12px; */
}

.workspace-entry-toggle .react-toggle-track {
  border-radius: 20px;
}

.workspace-entry-toggle .react-toggle-thumb {
  border-radius: 100%;
}

.workspace-entry-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1.5em;
  align-items: center;
  width: 100%;
  @apply p-2 pt-0;
}

.workspace-entry-info > p {
  margin: 2.5px;
}

.workspace-entry-info {
  display: flex;
  flex-direction: column;
}

.workspace-entry-controls {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 10px;
  min-width: 7.5rem;
}

.export-modal {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.export-modal > button {
  margin: auto;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.export-modal > button:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.export-modal > p {
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.2em;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}
